#general-description{
    background: transparent linear-gradient(0deg, #FFFFFF 25%, #E4EEF1 100%) 0% 0% no-repeat padding-box;
    padding-bottom: 20px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-bottom: 45px;
    }
    @media screen and (min-width: $breakpoint-large) {
        padding-bottom: 90px;
    }
    .right-container{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        @media screen and (min-width: $breakpoint-large) {
            margin-top: 0px;
        }

        .description-wrapper{

            margin-top: 70px;
            width: 100%;
            @media screen and (min-width: $breakpoint-large) {
                margin-top: 0px;
                width: 60%;
            }
            h2 {
                font-family: $font-bold;
                font-size: 22px;
                width: auto;
                color: #313131;
                margin-block-start: 0;
                margin-block-end: 0;
                margin-top: 0px;

                @media screen and (min-width: $breakpoint-medium) {
                    font-size: 25px;
                }

                @media screen and (min-width: $breakpoint-large) {
                    margin-top: 60px;
                }

                @media screen and (min-width: $breakpoint-extra-large) {
                    font-family: $font-semi-bold;
                    font-size: 28px;
                    margin-top: 80px;
                    color: #112A60;
                }
            }
            h1{
                font-family: $font-semi-bold;
                font-size: 16px;
                color: #313131;
                margin-block-start: 0;
                margin-block-end: 0;
                line-height: 1.6rem;
                width: auto;
                margin-top: 40px;

                @media screen and (min-width: $breakpoint-medium) {
                    font-size: 18px;
                }


                @media screen and (min-width: $breakpoint-extra-large) {
                    margin-top: 25px;
                }

            }
            p{
                font-family: $font-regular;
                font-size: 18px;
                line-height: 1.5;
                @media screen and (min-width: $breakpoint-large) {
                    font-size: 20px;
                }
            }
        }
        .description-wrapper-list{
            margin-top: 20px;
            display: block;
            @media screen and (min-width:  $breakpoint-medium) {
                display: flex;
                align-self: center;
            }
            @media screen and (min-width: $breakpoint-large) {
                   align-self: inherit;
                   align-items: center;

            }

            @media screen and (min-width: $breakpoint-extra-large) {
                align-self: inherit;
                align-items: flex-start;
            }

            ul {
                display: block;
                float: left;
                width: 100%;
                padding-inline-start: 0px;
                margin-left: 0px;
                margin-right: 57px;

                @media screen and (min-width: $breakpoint-medium) {
                    max-width: 540px;

                    margin: 0 auto;
                    margin-left: -35px;
                }

                @media screen and (min-width: $breakpoint-large) {
                    margin-left: 0px;
                    width: 55%;
                }

                li{
                    list-style: none;
                    background: url("../../../assets/images/list_hyphen.png") no-repeat 0px 30px;
                    padding: 15px 18px 15px 40px;
                    width: 100%;

                    @media screen and (min-width: $breakpoint-medium) {
                        padding: 15px 0px 15px 47px;
                    }

                    @media screen and (min-width: $breakpoint-extra-large) {
                        padding: 20px 0px 20px 47px;
                    }

                    span{
                        font-size: 16px;
                        font-family: $font-regular;
                        color: #313131;
                        line-height: 1.8rem;
                        @media screen and (min-width: $breakpoint-medium) {
                            font-size: 18px;
                        }
                    }
            }
        }
    }
        .parking-sign{
            clip-path: none;
            width: 100%;
            display: none;
            @media screen and (min-width: $breakpoint-medium) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                display: none;
            }
            @media screen and (min-width: $breakpoint-large) {
                display: block;
                width: 58%;
            }
            .cropped {
                display: none;
                @media screen and (min-width: $breakpoint-large) {
                    display: block;
                }
            }
        }

        .parking-sign-cropped{
            display: block;
            margin-left: -35px;
            @media screen and (min-width: $breakpoint-small) {
                height: auto;
            }

            @media screen and (min-width: $breakpoint-large) {
                display: none;
            }

        }
    }

}
