#components-list{
    background: transparent linear-gradient(0deg, #FFFFFF 30%, #E4EEF1 100%) 0% 0% no-repeat padding-box;
    padding-top: 0px;
    margin-top: 50px;

    @media screen and (min-width: $breakpoint-large) {
        padding-top: 90px;
        margin-top: 0px;
    }

    .left-container{
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: column;
        }

        @media screen and (min-width: $breakpoint-large) {
            flex-direction: row;
          //  align-items: center;
        }

        @media screen and (min-width: $breakpoint-extra-large) {
            align-items: center;
        }


        .inspector-and-smartphone {
            display: block;
            width: 100%;
            @media screen and (min-width: $breakpoint-large) {
                width: 45%;
            }

            @media screen and (min-width: $breakpoint-extra-large) {
                width: 40%;
            }

            .desktop{
                display: none;
                @media screen and (min-width: $breakpoint-large) {
                    display: block;
                }
            }

            .tablet{
                display: none;
                @media screen and (min-width: $breakpoint-medium) {
                    display: block;
                }
                @media screen and (min-width: $breakpoint-large) {
                    display: none;
                }
            }

            .mobile{
                display: block;
                @media screen and (min-width: $breakpoint-medium) {
                    display: none;
                }
                @media screen and (min-width: $breakpoint-large) {
                    display: none;
                }
            }

            img{
                width: 100%;
            }
        }

        .components-list-wrapper{
            width: 100%;
            padding-left: $general-margin-small;
            padding-right: $general-margin-small;
            margin-top: 56px;

            @media screen and (min-width: $breakpoint-medium) {
                padding-left: $general-margin-medium;
                padding-right: $general-margin-medium;
                margin-top: 0px;
            }

            @media screen and (min-width: $breakpoint-large) {
                padding-left: 60px;
                padding-right: 0;
                width: 600px;
            }

            @media screen and (min-width: $breakpoint-extra-large) {
                padding-left: 76px;
                width: 700px;
            }

            .smartphone{
                display: block;
                width: 60%;
                margin: 0 auto;
                max-width: 250px;
                @media screen and (min-width: $breakpoint-medium) {
                    display: none;
                }
            }
            h2{
                margin-top: 0px;
                margin-bottom: 50px;
                font-family: $font-bold;
                font-size: 22px;
                width: 100%;
                color: #112A60;

                @media screen and (min-width: $breakpoint-medium) {
                    font-size: 25px;
                }

                @media screen and (min-width: $breakpoint-large){
                    margin-bottom: 25px;
                }

                @media screen and (min-width: $breakpoint-extra-large) {
                    font-family: $font-semi-bold;
                    font-size: 28px;
                }
            }

            p{
                font-size: 16px;
                color:#313131;
                line-height: 28px;
                margin-block-start: 1.5em;
                margin-block-end: 1.5em;

                span{
                    font-family: $font-bold;
                }

                @media screen and (min-width: $breakpoint-small) {
                    font-size: 18px;
                }

            }
        }
    }
    .appointment-inspection-container{
        padding: 50px 0px;
        @media screen and (min-width: $breakpoint-small) {
            padding: 50px 0px;
        }
    h2{
      color:#313131;
      font-size: 16px;
      width: 100%;
      font-family: $font-bold;
      text-align: left;
      margin-top: 0;
      padding-left: 0px;

      @media screen and (min-width: $breakpoint-medium) {
        font-size: 18px;
        padding-left: 0px;
        text-align: left;
      }

      @media screen and (min-width: $breakpoint-large) {
        font-size: 20px;
        text-align: center;
        padding-left: 0px;
      }
    }
    .appointment-inspection-block{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 30px;

        @media screen and (min-width: $breakpoint-medium) {
            align-items: center;
            margin-top: 60px;
        }

        @media screen and (min-width: $breakpoint-large) {
            flex-direction: row;
            align-items: flex-start;
        }

        .appointment-inspection-top{
            display: flex;
            flex-direction: column;
            @media screen and (min-width: $breakpoint-medium) {
                flex-direction: row;
            }
            @media screen and (min-width: $breakpoint-large) {
                flex-direction: row;
            }
            .appointment-inspection-item{
                &:first-child{
                    margin-top: 0px;
                }
            }
        }
        .appointment-inspection-bottom{
            display: flex;
            flex-direction: column;
            @media screen and (min-width: $breakpoint-medium) {
                flex-direction: row;
                margin-top: 60px;
            }
            @media screen and (min-width: $breakpoint-large) {
                flex-direction: row;
                margin-top: 0px;
            }
            .appointment-inspection-item{
                &:last-child{
                    margin-right: 0px;

                    @media screen and (min-width: $breakpoint-medium) {
                        margin-left: 50px;
                    }
                    @media screen and (min-width: $breakpoint-large) {
                        margin-left: 0px;
                    }
                }
            }
        }

        .appointment-inspection-item{
            display: flex;
            flex-direction: row;
            margin-top: 50px;
            align-items: center;


            @media screen and (min-width: $breakpoint-medium) {
               margin-top: 0px;
               margin-right: 20px;
            }

            @media screen and (min-width: $breakpoint-large) {
                flex-direction: column;
                margin-right: 35px;
                align-items: flex-start;
                &:first-child{
                    margin-left: 0px;
                }
            }

            .appointment-inspection-label{
                max-width: initial;
                color:#313131;
                font-size: 15px;
                margin: 0 0px ;
                line-height: 22px;

                @media screen and (min-width: $breakpoint-large) {
                    max-width: 238px;
                }

                @media screen and (min-width: $breakpoint-extra-large) {
                    font-size: 15px;
                    font-family: $font-regular;
                }
            }
            .icon{
                margin-bottom: 0;
                margin-right: 20px;
                flex: none;
                @media screen and (min-width: $breakpoint-large) {
                    margin-bottom: 20px;
                    margin-right: 0px;
                }
                &.street-inspection{
                    background-image: url('../../../assets/images/street-inspection.png');
                    background-repeat: no-repeat;
                    width: 57px;
                    height: 57px;
                    background-position-x: -7px;
                }
                &.implementation-fixation{
                    background-image: url('../../../assets/images/implementation-fixation.png');
                    background-repeat: no-repeat;
                    width: 57px;
                    height: 60px;
                    @media screen and (min-width: $breakpoint-medium) {
                        width: 39px;
                    }
                }
                &.selection-violations{
                    background-image: url('../../../assets/images/selection-violations.png');
                    background-repeat: no-repeat;
                    width: 57px;
                    height: 57px;
                    @media screen and (min-width: $breakpoint-medium) {
                        width: 55px;
                    }
                }
                &.print-message{
                    background-image: url('../../../assets/images/print-message.png');
                    background-repeat: no-repeat;
                    width: 57px;
                    height: 62px;
                    @media screen and (min-width: $breakpoint-medium) {
                        width: 44px;
                    }
                }
            }
        }
    }
}
}
