#advantages{
    background: linear-gradient(#DBF2FE, #FEF3DF);
    padding-top: 30px;
    padding-bottom: 65px;
    .container{
        h2 {
            margin-top: 18px;
            @media screen and (min-width: $breakpoint-medium) {
                text-align: center;
            }

            @media screen and (min-width: $breakpoint-large) {
                margin-top: 72px;
            }
            width: 100%;
            font-family: $font-semi-bold;
        }
        
        p{
            font-size: 18px;
            line-height: 1.5;
            @media screen and (min-width: $breakpoint-medium) {
                text-align: center;
            }
            @media screen and (min-width: $breakpoint-large) {
                font-size: 19px;
            }
        }

        .advantages-wrapper{
            display: flex;
            align-items: center;
            @media screen and (min-width: $breakpoint-large) {
                flex-direction: row;
                justify-content: center;
                margin-top: 50px;
            }
            flex-direction: column;
            margin-top: 28px;
            margin-bottom: 50px;
            .top-row{
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $breakpoint-medium) {
                    flex-direction: row;
                }
            }
            .bottom-row{
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $breakpoint-medium) {
                    flex-direction: row;
                }
            }
            .advantage-item{
                width: 200px;
                margin-bottom: 50px;
                .circle{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 103px;
                    height: 103px;
                    background: #fff;
                    border-radius: 50%;
                    margin: 0 auto;
                    &.budget{
                        background: #fff url("../../../assets/images/icons_inspector_01.png") no-repeat; 
                        background-size: 80%;
                        background-position-x: center;
                        background-position-y: center;
                    }
                    &.auto-fixation{
                        background: #fff url("../../../assets/images/icons_inspector_07.png") no-repeat; 
                        background-size: 80%;
                        background-position-x: center;
                        background-position-y: center;
                    }
                    &.road-safety{
                        background: #fff url("../../../assets/images/icons_inspector_03.png") no-repeat; 
                        background-size: 80%;
                        background-position-x: center;
                        background-position-y: center;
                    }
                    &.street-parking-management{
                        background: #fff url("../../../assets/images/icons_inspector_05.png") no-repeat; 
                        background-size: 80%;
                        background-position-x: center;
                        background-position-y: center;
                    }
                    &.mobile-payment{
                        background: #fff url("../../../assets/images/icons_inspector_09.png") no-repeat; 
                        background-size: 80%;
                        background-position-x: center;
                        background-position-y: center;
                    }
                }
                .advantage-label{
                    text-align: center;
                    margin-top: 25px;
                }
                
            } 
        }

        button {
            display: block;
            margin: 0 auto;
            height: 51px;
            border-radius: 3px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    
}