#app-main{
    h2{
        margin-top: 72px;
        font-family: $font-semi-bold;
        font-size: 28px;
        color: #112A60;
        width: auto;
        @media screen and (min-width: $breakpoint-large) {
            font-size: 33px;
            font-family: $font-semi-bold;
            width: 800px;
        }
    }
}

#goal{
    padding-bottom: 0px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-bottom: 30px;
    }

    h2{
        margin-top: 52px;
        @media screen and (min-width: $breakpoint-large) {
            margin-top: 72px;
        }
    }
    p{
        &:first-child{
            margin-top: 52px;
            @media screen and (min-width: $breakpoint-large) {
                margin-top: 72px;
            }
        }
        font-family: $font-regular;
        font-size: 16px;
        line-height: 2rem;
        color: #313131;

        @media screen and (min-width: $breakpoint-medium) {
            font-size: 18px;
        }
    }
}

#parking-inspector {
    padding-top: 0px;
    padding-bottom: 10px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-bottom: 90px;
        padding-top: 0px;
        margin-top: -42px;
    }
    @media screen and (min-width: $breakpoint-large) {
        padding-top: 28px;
        margin-top: 0px;
    }
    .inspector {
        display: block;
        @media screen and (min-width: $breakpoint-medium) {
            display: none;
        }
    }
    .inspector-and-smartphone{
        display: none;
        @media screen and (min-width: $breakpoint-medium) {
            display: block;
        }
        @media screen and (min-width: $breakpoint-large) {
            display: none;
        }
    }
    p{
        font-family: $font-regular;
        font-size: 20px;
        line-height: 1.5;
        @media screen and (min-width: $breakpoint-large) {
            font-size: 20px;
        }
    }
    h2{
        margin-bottom: 21px;
        margin-top: 40px;
        @media screen and (min-width: $breakpoint-large) {
            margin-top: 72px;
        }
    }
}

.orange-button{
    background: #FCAD10;
    color: #fff;
    border: solid 1px #C8C5C5;
    border-radius: 3px;

    .loader{
        margin: 0 auto;
    }
}



.city-buildings{
    display: flex;
    img{
        display: none;
        @media screen and (min-width: $breakpoint-medium) {
            display: block;
        }
        margin: 0 auto;
    }
}

.loader-large {
    border: 6px solid #E7E7E7;
    border-top: 6px solid #C9C9C9;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: load8 1s linear infinite;
    position: relative;
    top: calc(50% - 50px);
    left: calc(50% - 25px);
}

.loader-logo,
.loader {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  position: relative;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.loader-logo{
  border-left: 3px solid rgba(252, 173, 16, 1);
}
@keyframes load8 {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
