#navigation-bar{
    height: 80px;
    background: #E4EEF1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;
    flex: none;

    @media screen and (min-width: $breakpoint-small) {
        padding-left: $general-margin-small;
        padding-right: $general-margin-small;
    }

    @media screen and (min-width: $breakpoint-medium) {
        padding-left: $general-margin-medium;
        padding-right: $general-margin-medium;
    }

    @media screen and (min-width: $breakpoint-extra-large) {
        padding-left: 38px;
        padding-right: 30px;
    }

    @media screen and (min-width: $breakpoint-extra-large) {
        padding-left: 38px;
        padding-right: 38px;
    }



    .container{
        margin-left: $general-margin-small;
        margin-right: $general-margin-small;

        @media screen and (min-width: $breakpoint-medium) {
            margin-left: $general-margin-medium;
            margin-right: $general-margin-medium;
        }
        @media screen and (min-width: $breakpoint-large) {
            margin-left: $general-margin;
            margin-right: 0;
        }
    }
    align-items: center;
    .nav ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        list-style: none;

        li{
            display: none;
            @media screen and (min-width: 1000px) {
                display: block;
            }
        }
    }

    .nav-link {
        cursor: pointer;
        display: block;
       padding: .5rem 1.5rem;
        text-decoration: none;
        background-color: transparent;
        color: #313131;
        font-family: $font-bold;
        font-size: 13px;

        @media screen and (min-width: $breakpoint-extra-large) {
            font-size: 14px;

        }
    }

    .btn-check-violations{
        float: left;
        color: #fff;
        font-family: $font-semi-bold;
        font-size: 10px;
        background-color: #112A60;
        padding: 11px 11px;
        margin-left: 0rem;
        border-radius: 5px;
        text-align: center;

        @media screen and (min-width: $breakpoint-small) {
            font-size: 14px;
            font-family: $font-bold;
            margin-left: 1.5rem;
            padding: .5rem 1.5rem;
        }
    }


    .nav{
        .back-button{
            cursor: pointer;
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 24 24" width="40"><path fill="#757575" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/><path d="M0 0h24v24H0V0z" fill="none"/></svg>');
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
        }
    }


    .logo-wrapper{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 35px;
        margin-left: 0;
        &.cv{
            min-width: 100px;
        }

        img{
            width: auto;
            height: 31px;
        }

      .logo-line{
        border-left: 1px solid #A5B5B9;
        width: 0px;
        height: 51px;
        margin: 0 30px
      }

      .logo-city{
          display: flex;
          align-items: center;
          span{
              margin-left: 20px;
              font-size: 12px;
              font-family: $font-semi-bold;
              color: #313131;
          }
      }
    }
}

//
// Burger menu custom styles
//
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 25px;
    display: none;
    @media screen and (min-width: 1000px) {
        display: none;
    }
}

// Outline for burger button focus state
.bm-burger-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 8px;
}

// Background color for burger bars focus state
.bm-burger-button {
    button:focus+span {
        span.bm-burger-bars {
            background-color: #c94e50;
        }
    }
}

.right .bm-burger-button {
    left: initial;
    right: 36px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-menu {
    background: #112a60;

    a {
        color: #efefef;

        &:hover,
        &:focus {
            color: #FCAD10;
        }
    }
}

.bm-item-list {
    height: auto !important;
    a {
        padding: 0.8em;

        span {
            margin-left: 10px;
            font-weight: 700;
        }
    }
    .nav{
        ul{
            padding-inline-start: 0px;
            margin-top: 22px;
            li{
                display: flex;
                padding-bottom: 30px;
                a{
                    padding: 0px;
                }
            }
        }
    }
}

.bm-item:focus {
    outline: none;
}


.bm-cross {
    background: #bdc3c7;
    width: 3px !important;
    height: 22px !important;
}

.bm-menu {
    padding: 1.5em 2.5em 0;
    //font-size: 1.15em;
    font-size: 1rem;
}

// Outline for cross button focus state
.bm-cross-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 2px;
}

// Background color for cross focus state
.bm-cross-button {
    button{
        top: 6px !important;
        outline: none;
    }
    button:focus+span {
        span.bm-cross {
            background-color: #c94e50;
        }
    }
}

.hamburger {
    cursor: pointer;
    @media screen and (min-width: 1000px) {
        display: none;
    }
    span {
        display: block;
        width: 30px;
        height: 3px;
        margin: 6px auto;
        background-color: #333;
        transition: all 0.3s ease-in-out;
        display: block;
     
      }
}