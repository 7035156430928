.pop-up-gallery-container{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);

    .pop-up-gallery-content{

        display: flex;
        flex-direction: column;
        position: relative;
        margin: 20px;
        max-width: 898px;
        background-color: #fff;
        max-height: calc(100% - 70px);
        overflow-y:auto;
        border-radius: 5px;

        .violation-info{
            margin-top: 20px;
            .violation-number{
                font-family: $font-semi-bold;
                font-size: 16px;
                @media screen and (min-width: $breakpoint-large) {
                    font-size: 18px;
                }
            }
            .violation-date{
                margin-top: 20px;
                font-size: 14px;
            }
            .violation-address{
                font-size: 14px;
            }
            .violation-types{
                font-size: 14px;
                margin-top: 20px;
                color: #ff0000;
                .violation-type-item{
                    margin-bottom: 10px;
                }
            }
            .violation-value{
                font-family: $font-semi-bold;
                font-size: 14px;
            }

        }

        .photo-report{
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: $font-semi-bold;
            font-size: 16px;
            @media screen and (min-width: $breakpoint-large) {
                font-size: 18px;
            }
        }
        @media screen and (min-width: $breakpoint-small) {
            margin: 32px;
        }

        .pop-up-title{
            display: flex;
            justify-content:space-between;
            align-items: center;
            margin-top: 24px;
            margin-left: 20px;
            @media screen and (min-width: $breakpoint-large) {
                margin-left: 40px;
            }
            margin-right: 5px;
            .pop-up-close-button{
                cursor: pointer;
                background-image: url('../assets/images/close.svg');
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: 110%;
                position: absolute;
                right: 22px;
                top: 22px;
            }
            .title{
                font-family: $font-semi-bold;
                //font-size: 0.75rem;
                font-weight: 600;
                // @media screen and (min-width: 540px) {
                //     font-size: 1rem;
                // }
                font-size: 22px;
                @media screen and (min-width: $breakpoint-large) {
                    font-size: 25px;
                }
            }
        }

        .pop-up-content{
            flex: 1 1 auto;
            padding: 0px 20px;
            padding-right: 14px;
            @media screen and (min-width: $breakpoint-large) {
                padding: 0px 40px;
                padding-right: 36px;
            }

            overflow: auto;
        }

        .pop-up-bottom{
            flex: 0 0 auto;
            display: flex;
            padding-bottom: 25px;
            @media screen and (min-width: $breakpoint-large) {
                padding-bottom: 40px;
            }
            align-items: center;
            justify-content: flex-end;
        }
    }
}


.image-gallery{
    .image-gallery-content{
        .image-gallery-slide-wrapper{
            &.right{
                @media (max-width: 600px){
                    width: 100% ;
                }
            }
        }

        .image-gallery-thumbnails-wrapper{
            display: none;
            @media screen and (min-width: 600px )  {
                display: inline-block;
            }
        }

        .image-gallery-slides{
            .image-gallery-slide {
                max-height: inherit;
                img{
                    object-fit: contain;
                    max-height: inherit;
                }
            }
        }
        .image-gallery-thumbnail{
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}
