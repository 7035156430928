.violation-modal{
    .modal-body{
        padding: 0px !important;
        height: 100%;
        .violation-modal-body{
            display: flex;
            height: 100%;
            flex-direction: column-reverse;
            @media screen and (min-width: $breakpoint-medium){
                flex-direction: row;
            }
            .violation-modal-left{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: auto;
                background-color: #E4EEF1;
                
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                //height: 132px;
                @media screen and (min-width: $breakpoint-medium){
                    width: 252px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 25px;
                    padding-top: 45px;
                    padding-bottom: 45px;
                    padding-right: 0px;
                  //  height: 100%;
                }
                &__title{
                    font-size: 25px;
                    font-family: $font-bold;
                    display: none;
                    @media screen and (min-width: $breakpoint-medium){
                        display: block;
                    }
                }
                &__contacts{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @media screen and (min-width: $breakpoint-medium){
                        align-items: flex-start;
                    }
                }
                &__help{
                    font-size: 13px;
                    margin-bottom: 13px;
                    max-width: 227px;
                    text-align: center;
                    @media screen and (min-width: $breakpoint-medium){
                        text-align: left;
                    }
                }
                &__phone{
                    font-size: 13px;
                    font-family: $font-semi-bold;
                }
            }
            .violation-modal-right{
                padding-left: 25px;
                padding-top: 30px;
                padding-bottom: 15px;
                padding-right: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                //height: 100%;
                @media screen and (min-width: $breakpoint-medium){
                    align-items: flex-start;    
                    padding-right: 0px;
                    padding-left: 25px;
                    padding-top: 45px;
                    //padding-bottom: 45px;
                }
                &__title{
                    font-size: 21px;
                    font-family: $font-bold;
                    display: block;
                    @media screen and (min-width: $breakpoint-medium){
                        display: none;
                    }
                }
                &__label{
                    font-size: 13px;
                    margin-top: 50px;
                    @media screen and (min-width: $breakpoint-medium){
                        margin-top: 0px;
                    }
                }
                &__lic-plate{
                    font-family: $font-bold;
                    margin-top: 7px;
                }
                &__description{
                    font-size: 14px;
                    max-width: 287px;
                    margin-top: 28px;
                    text-align: center;
                    @media screen and (min-width: $breakpoint-medium){
                        text-align: left;
                        max-width: 349px;
                        margin-top: 44px;
                    }
                }
                &__form{
                    margin-top: 17px;
                    display: flex;
                    .MuiTextField-root{
                        width: 217px !important;
                    }
                    .check-btn{
                        width: 217px !important;
                        height: 45px!important;
                        margin-left: 0px;
                        font-size: 14px;
                        @media screen and (min-width: $breakpoint-medium){
                            width: 136px !important;
                            margin-left: 11px;
                        }
                    }
                    flex-direction: column;
                    @media screen and (min-width: $breakpoint-medium){
                        flex-direction: row;
                    }
                }
            }
        }
    }
}