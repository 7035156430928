#violation-form{
    padding: 30px 0;

    .laws-regulations{
        margin-top: 30px;
        width: 100%;
        padding:  10px;

        max-width: 290px;
        .legislation-item{
            background-color: #E4EEF1;
            padding: 10px;
        }
        .order-pay-fine-item{
            background-color: #E4EEF1;
            margin-top: 15px;
            padding: 10px;
        }

        @media screen and (min-width: $breakpoint-medium) {
            width: auto;
            padding: 0;
            background: none;
            max-width: 100%;
        }
    }

    .info-form{
        margin-top: 25px;
        width: 100%;

        @media screen and (min-width: $breakpoint-medium) {
            width: 50%;
        }

        @media screen and (min-width: $breakpoint-extra-large) {
            width: 40%;
        }
        .laws-regulations{
            display: none;
            @media screen and (min-width: $breakpoint-medium) {
                display: block;
            }
            .legislation-item{
                background-color: white;
                padding: 0px;
            }
            .order-pay-fine-item{
                background-color: white;
                margin-top: 15px;
                padding: 0px;
            }
        }
        h2{
            font-size: 22px;
            margin-top: 0;
            font-family: $font-bold;
            color: #313131;
        }
        >p{
            margin-block-start: 18px;
            margin-block-end: 18px;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 35px;
        }

        .line-bottom{
            display: none;
            border-bottom:solid 1px #49b7f0 ;
            height: 0;
            width: 90%;
            @media screen and (min-width: $breakpoint-medium) {
                display: block;
            }
        }
    }
    .container{
        display: flex;
        flex-direction: column;
        margin-left: $general-margin-small;
        margin-right: $general-margin-small;
        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: row;
            margin-left: $general-margin-medium;
            margin-right: $general-margin-medium;
        }
        @media screen and (min-width: $breakpoint-large) {
            margin-left: 125px;
            margin-right: 125px;
        }

        @media screen and (min-width: $breakpoint-extra-large) {
            margin-left: 24%;
            margin-right: 24%;
        }
    }

    .violation-form-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 0px;

        @media screen and (min-width: $breakpoint-medium) {
            padding-left: 80px;
            width: 50%;
        }

        @media screen and (min-width: $breakpoint-extra-large) {
            width: 60%;
        }

        .MuiTextField-root{
            margin-bottom: 20px;
            width: 100%;
            @media screen and (min-width: 360px) {
                width: 290px;
            }

            @media screen and (min-width: $breakpoint-medium) {
                width: 289px;
            }
            .MuiInputBase-input{
                border: none;
                padding-top: 13px;
                padding-bottom: 13px;
            }

        }

        .MuiInputLabel-outlined.MuiInputLabel-marginDense{
            transform: translate(14px, 14px) scale(1);
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink{
            transform: translate(14px, -6px) scale(0.8);
        }

        .laws-regulations{
            padding-left: 0px;
            padding-right: 0px;
        }
        form {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media screen and (min-width: $breakpoint-large) {
                align-items: center;
                width: 380px;
                //box-shadow: 0px 8px 19px rgba($color: #000000, $alpha: 0.17);
            }

            .laws-regulations{
                display: block;
                @media screen and (min-width: $breakpoint-medium) {
                    display: none;
                }
            }

            .form-group{
                width: 100%;
                @media screen and (min-width: 360px) {
                    width: 290px;
                }

                @media screen and (min-width: $breakpoint-medium) {
                    width: 289px;
                }

                label{
                    font-size: 14px;
                    font-family: $font-bold;
                    margin-bottom: 2px;
                }

                input {
                    text-transform: uppercase;
                    &:invalid{
                        border: solid 1px red;
                    }
                    &::placeholder,
                    -ms-input-placeholder,
                    -webkit-input-placeholder{
                        color:#A5B5B9;
                        font-size: 12px;
                    }
                    width: 100%;
                    margin-bottom: 16px;
                    background-color: #ffffff;
                    padding:  0 16px;
                    height: 42px;
                    border-radius: 0;
                    color:#313131;
                    font-size: 20px;
                }

                input:-webkit-autofill {
                    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
                    background-color: #ffffff;
                }
            }
            button.check-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 51px;
                cursor: pointer;

                @media screen and (min-width: 360px) {
                    width: 290px;

                }
                @media screen and (min-width: $breakpoint-medium) {

                    width: 289px;

                }
            }

            .bottom-text{
                display: block;
                padding-top: 16px;

                @media screen and (min-width: $breakpoint-medium){
                    padding-top: 19px;
                }
                span{
                    float: left;
                    font-size: 13px;
                    &:last-child{
                        margin-top: 11px;
                        font-size: 16px;
                        font-family: $font-semi-bold;
                    }
                }

                @media screen and (min-width: 360px) {
                    width: 290px;

                }
                @media screen and (min-width: $breakpoint-medium) {
                    width: 289px;

                }
            }
        }
        .form-message{
            color: red;
            font-family: $font-regular;
            font-size: 13px;
        }
        .message-container-responsive{
            display: block;
            min-height: 20px;
            margin-top: 10px;
            text-align: center;
            @media screen and (min-width: $breakpoint-medium) {
                text-align: left;
                margin-top: -10px;
            }
        }

    }
}

.order-pay-fine-dialog{
    @media screen and (min-width: $breakpoint-small) {
        max-width: 690px !important;
    }
}

.laws-regulations{
    span{
        cursor: pointer;
        font-family: $font-medium;
        font-size: 13px;
        color: #039CEB;
    }
}
