.contact-information-container{
    height: auto;
    display: flex;
    padding-bottom: 30px;

    @media screen and (min-width: $breakpoint-medium) {
        height: 575px;
        padding-bottom: 0px;
    }

    .left-img{
        display: none;
        background-image: url('../../../assets/images/unsplash.png');
        width: 327px;
        height: 100%;
        @media screen and (min-width: $breakpoint-medium) {
            display: block
        }
    }
    .contact-information-block{
        width: 100%;
        .close-container{
            padding-top:24px;
            padding-right: 20px;
            float: left;
            width:100%;
            .close-icon{
                float: right;
                cursor: pointer;
                background-image: url('../../../assets/images/close.svg');
                width: 24px;
                height: 24px;

            }
        }
        .container{
            float: left;
            margin-top: 0px;

            @media screen and (min-width: $breakpoint-small) {
                margin-top: 10px;
            }

            @media screen and (min-width: $breakpoint-large) {
                margin-top: 30px;
                margin-left: 47px;
                margin-right: 61px;
            }

            h2{
                font-size: 22px;
                color:#002A6C;
                font-family: $font-semi-bold;
                margin-block-start: 30px;
                @media screen and (min-width: $breakpoint-small) {
                    font-size: 28px;
                }

              @media screen and (min-width: $breakpoint-medium) {
                font-size: 25px;
              }

              @media screen and (min-width: $breakpoint-large) {
                font-size: 28px;
              }

            }
            p{
                font-size: 16px;
                color:#313131;
                margin-block-end: 0;
                @media screen and (min-width: $breakpoint-small) {
                    font-size: 18px;
                }
            }
            form {
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                @media screen and (min-width: $breakpoint-small) {
                    flex-direction: row;
                }
                .form-group{
                    &:last-child{
                        margin-left: 0px;
                        @media screen and (min-width: $breakpoint-small) {
                            margin-left: 40px;
                        }
                    }
                    width: 100%;
                    label{
                        font-size: 14px;
                        font-family: $font-bold;
                    }
                    input {
                        margin-top: 8px;
                        border-radius:3px;
                        width: 100%;
                        &:invalid{
                            border: solid 1px red;
                        }

                        margin-bottom: 20px;
                        background-color: #ffffff;
                        padding:  0 16px;
                    }

                    input:-webkit-autofill {
                        -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
                        background-color: #ffffff;
                    }

                }
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 161px;
                font-size: 15px;
                font-family: $font-bold;
                height: 53px;
                margin-top: 10px;
            }

        }
    }
}
