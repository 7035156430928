#order-pay-fine{
    
    h2{
        color:#112A60;
        font-size: 22px;
        font-family: $font-bold;
    }
    .order-pay-description{
        p{
            font-size: 14px;
            line-height: 1.5;

            &:first-child{
                margin-bottom: 30px;
            }
        }
    }

    .button-wrapper{
        display: flex;
        justify-content: center;
        padding: 20px;
    
        button{
            min-width: 200px;
            height: 40px;
            cursor: pointer;
            font-size: 14px;
        }
    }
    
}