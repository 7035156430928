.questions-box{
    width: 100%;
    .popular-question{
        font-size: 12px;
        padding-bottom: 20px;
    }
    .question-item{
        background: #E4EEF1;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: $font-semi-bold;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        label{
            cursor: pointer;
            //color: #039CEB;
            margin-left: 10px;
        }
        // input{
        //     display: none;
        // }
    }
    button{
        height: 40px;
        width: 120px;
        cursor: pointer;
    }
}

.rsc{
    .rsc-container{
        border-radius: 16px;
        height: 600px;
        .rsc-header{
            height: 62px;
            padding-left: 20px;
            padding-right: 20px;
            .rsc-header-title{
                font-family: $font-bold;
            }
        }
    }
    
    .welcome-bot{
        background: #E4EEF1;
        text-align: center;
        max-width: 309px;
        margin: 0 auto;
        font-size: 14px;
        img.chat-icon{
            width: 65px;
            height: 65px;
            margin-bottom: 15px;
        }
    }

    .rsc-ts-user{
        .rsc-ts-bubble{
            font-size: 14px;
            font-family: $font-semi-bold;
        }
    }

    .rsc-content{
        padding-bottom: 20px;
        height: 544px !important;
        &::-webkit-scrollbar-track
        {
            background-color: #A5B5B9;
            border-radius: 15px;
        }
        &::-webkit-scrollbar
        {
            width: 6px;
            background-color: #A5B5B9;
        }
        &::-webkit-scrollbar-thumb
        {
            background-color: #112A60;
            border-radius: 15px;
        }
        .rsc-cs{
            &:first-child{
                background: #E4EEF1;
                box-shadow: none;
            }
            margin-left: 19px;
            margin-right: 19px;
        }
    }

    .rsc-ts-bot{
        .QRoHW, .ilKDsL  {
            margin-left: 41px;
        }
    }

    .rsc-float-button {
        img{
            width: auto;
            height: auto;
        }
    }

    .dlXTUx, .dJJgye{
        width: 40px;
    }
    
    .rsc-ts-bubble{
        max-width: 84% !important;
    }
    
    .rsc-os-option{
        .rsc-os-option-element{
            cursor: pointer;
        }
    }
    
    
}

.show-list-of-question{
    cursor: pointer;
    background: #fff;
    border: 0;
    border-radius: 22px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
    color: #313131;
    display: inline-block;
    font-size: 14px;
    padding: 12px;
    font-family: $font-bold;
}