// Montserrat
@font-face {
    font-family: Montserrat-Regular;
    src: url('./../assets/fonts/Montserrat-Regular.woff2');
    font-display: swap;
}

@font-face {
    font-family: Montserrat-Medium;
    font-display: swap;
    src: url('./../assets/fonts/Montserrat-Medium.woff2');
}

@font-face {
    font-family: Montserrat-Light;
    font-display: swap;
    src: url('./../assets/fonts/Montserrat-Light.woff2');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('./../assets/fonts/Montserrat-Bold.woff2');
    font-display: swap;
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('./../assets/fonts/Montserrat-SemiBold.woff2');
    font-display: swap;
}
