$breakpoint-small-statistic: 400px;
#statistic{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000026;
    padding-top: 50px;
    padding-bottom: 52px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-top: 53px;
        padding-bottom: 81px;
    }

    @media screen and (min-width: $breakpoint-large) {
        padding-top: 52px;
        padding-bottom: 68px;
    }

    .wide-container {
      h2 {
        margin-top: 0px;
        color: #313131;
        font-size: 20px;
        font-family: $font-bold;
        margin-bottom: 40px;
      }

      .statistic-description {
        font-family: $font-semi-bold;
        line-height: 24px;
        max-width: 560px;
        min-height: 42px;
        font-size: 14px;
        margin-bottom: 15px;
        //padding-left: 60px;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $breakpoint-small-statistic) {
          flex-direction: row;
        }
        @media screen and (min-width: $breakpoint-medium) {
            font-size: 16px;
        }
        .icon{
          margin-right: 15px;
        }
        &.decree{
          background: url("../../../../../assets/images/ic_statistics_check.svg") no-repeat;
          background-position-y: center;
        }
        &.paid{
          background: url("../../../../../assets/images/ic_statistics_inputs.svg") no-repeat;
          background-position-y: center;
        }
      }
    }

        .privilege{
          font-size: 16px;
          font-family: $font-bold;
          max-width: 550px;
          margin-top: 20px;
          margin-bottom: 20px;
          @media screen and (min-width: $breakpoint-medium) {
            font-size: 18px;
          }
        }

        .statistic-row{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          @media screen and (min-width: $breakpoint-medium) {
            margin-bottom: 45px;
          }
          @media screen and (min-width: 970px) {
            flex-direction: row;
          }
        }

        .statistic-fields-wrapper {
          //margin-top: 40px;
          @media screen and (min-width: $breakpoint-medium) {
            align-items: flex-start;
          }
          @media screen and (min-width: 1400px) {
            flex-direction: row;
          }

          .statistic-field {
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            font-size: 14px;
            padding-top: 10px;
            margin-bottom: 20px;
            @media screen and (min-width: $breakpoint-small-statistic) {
              padding-top: 10px;
              padding-left: 62px;
            }
            @media screen and (min-width: 970px) {
              margin-bottom: 0px;
            }
            @media screen and (min-width: 1400px) {
              min-width: initial;
            }

            .statistic-label{
              font-size: 14px;
              @media screen and (min-width: $breakpoint-small){
                font-size: 16px;
              }
              @media screen and (min-width: $breakpoint-medium){
                font-size: 18px;
              }
            }

            &.month {
              //background: url("../assets/images/ic_statistis1.png") no-repeat;
              background-size: 42px;
              width: auto;
              //margin-top: 72px;
              @media screen and (min-width: $breakpoint-medium){
                width: 470px;
              }
              @media screen and (min-width: 1400px) {
                //margin-left: 140px;
                margin-top: 0px;
              }
            }

            &.last-year,
            &.year {
              //background: url("../assets/images/iic_statistis2.png") no-repeat;
              background-size: 42px;
              @media screen and (min-width: 1400px) {
                margin-top: 0px;
              }
            }

            &.last-year {
              width: auto;
              @media screen and (min-width: $breakpoint-medium){
                width: 470px;
              }
            }

            .statistic-value-wrapper {
              font-size: 25px;
              
              color: #313131;
              display: flex;
              margin-top: 10px;
              flex-direction: column;
              @media screen and (min-width: $breakpoint-small-statistic) {
                flex-direction: row;
                margin-top: 15px;
              }
              @media screen and (min-width: $breakpoint-medium) {
                font-size: 40px;
              }
              font-family: $font-light;
              .medium{
                font-family: $font-medium;
              }

              .protocols {
                min-width: 110px;
                padding-right: 20px;
                border-right: none;
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $breakpoint-small-statistic) {
                  border-right: 1px solid #A5B5B9;
                }
              }

              .sum {
                display: flex;
                flex-direction: column;
                padding-left: 0px;
                margin-top: 10px;
                //word-break: break-all;
                @media screen and (min-width: $breakpoint-small-statistic) {
                  padding-left: 20px;
                  margin-top: 0px;
                }
              }

              span {
                font-size: 14px;
                margin-top: -5px;
              }
              .small-label{
                font-size: 14px;
                font-family: $font-medium;
                margin-top: -4px;
              }
            }
          }
        }
}
