// Grid
$breakpoint-extra-small: 360px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 1200px;
$breakpoint-extra-large: 1600px;

$container-size: 768px;

// Fonts Montserrat
$font-regular: Montserrat-Regular, sans-serif;
$font-medium: Montserrat-Medium, sans-serif;
$font-light: Montserrat-Light, sans-serif;
$font-bold: Montserrat-Bold, sans-serif;
$font-semi-bold: Montserrat-SemiBold, sans-serif;


//Margins
$general-margin: 18%;
//$general-margin: 10%;
$general-margin-small: 24px;
$general-margin-medium: 35px;
