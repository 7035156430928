.legislation-container{
    padding-top: 40px;
    padding-bottom: 100px;
    .screen-title{
        font-size: 14px;
        font-family: $font-bold;
        border-bottom: solid 3px #039CEB;
        max-width: 200px;
        padding-bottom: 3px;
        text-transform: uppercase;
        margin-left: 20px;
        @media screen and (min-width: $breakpoint-medium) {
            margin-left: 35px;
        }
        @media screen and (min-width: $breakpoint-large) {
            margin-left:  $general-margin;
        }
    }

    .content{
        float: left;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px; 
        padding-left: 20px;
        padding-right: 20px;

        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: row;
            padding-left: 35px;
            padding-right: 35px;
        }

        @media screen and (min-width: $breakpoint-large) {
            padding-left: $general-margin;
            padding-right: $general-margin;
        }

        .block{
            float: left;
            width: 100%;

            @media screen and (min-width: $breakpoint-medium) {
                width: 50%;
            }

            &:last-child{
                margin-left: 0;
                
                @media screen and (min-width: $breakpoint-medium) {
                    margin-left: 20px;
                }

                @media screen and (min-width: $breakpoint-large) {
                    margin-left: 70px;
                  
                }
        
            }
        }

        ul{
            margin: 0;
            padding-left: 0;
            li{
                list-style: none;
                margin-bottom: 25px;
                h2{
                    color: #313131;
                    font-family: $font-semi-bold;
                    font-size: 18px;
                    margin-block-start: 25px;
                    margin-block-end: 18px;

                }
                p{
                    color: #313131;
                    font-size: 14px;
                    margin-bottom: 0;
                    line-height: 1.5;
                    margin: 0;
                }
                a{
                    color: #039CEB;
                    font-size: 14px;
                }
                .notate{
                    margin-top: 20px;
                }
            }
        }
    }
}

.back-to{
    cursor: pointer;
    color: #313131;
    font-size: 14px;
    background: url('../../../assets/images/arrow_left.svg') no-repeat;
    padding-left: 30px;
    background-size: 27px;
    height: 25px;
    display: flex;
    margin-left: 10px;
    @media screen and (min-width: $breakpoint-medium) {
        margin-left: 26px;
    }
    @media screen and (min-width: $breakpoint-large) {
        display: none;
    }
    align-items: center;
    
    margin-top: 15px;
}