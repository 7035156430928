#app-main {
    #violationInformation {
        h2 {
            width: auto;
            font-size: 28px;
            margin-top: 25px;
            @media screen and (min-width: $breakpoint-small) {
                margin-top: 30px;
            }
            @media screen and (min-width: $breakpoint-medium) {
                font-size: 25px;
            }
        }
    }
}

.general-wrapper {
    &.violation-Information-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        #navigation-bar {
            display: flex;
            justify-content: flex-start;
            height: 60px;
            @media screen and (min-width: $breakpoint-small) {
                height: 80px;
            }
            .nav {
                width: 1200px;
                ul {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .logo-wrapper {
                justify-content: flex-start;
            }
        }
    }
}

#violationInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    padding-top: 20px;
    @media screen and (min-width: $breakpoint-large) {
        flex-direction: row;
        padding-top: 57px;
    }
}

.violation-container {
    padding-left: 24px;
    padding-right: 24px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.decree {
    @media screen and (min-width: $breakpoint-medium) {
        padding-left: 28px;
        padding-right: 28px;
    }
    @media screen and (min-width: $breakpoint-large) {
        max-width: 636px;
        margin-right: 72px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .violation-status-wrap {
        //box-shadow: 0px 0px 18px #00000029;
        @media screen and (min-width: $breakpoint-medium) {
            padding-top: 12px;
        }
        @media screen and (min-width: $breakpoint-large) {
            display: none;
        }
    }

    .violation-header-wrapper {
        .violation-status-wrap {
            //box-shadow: 0px 0px 18px #00000029;
            @media screen and (min-width: $breakpoint-medium) {
                display: none;
            }
        }
        .violation-header {
            @media screen and (min-width: $breakpoint-medium) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .description-and-payment {
        display: flex;
        flex-direction: column-reverse;
        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: row;
        }
        .violation-status-wrap {
            box-shadow: 0px 0px 18px #00000029;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 15px;
            margin-bottom: 10px;
            margin-left: 0px;
            min-width: 300px;
            @media screen and (min-width: $breakpoint-medium) {
                margin-left: 20px;
            }
            p {
                font-size: 14px;
                line-height: 1.7;
            }
            h2 {
                margin-top: 10px !important;
                margin-block-end: 10px !important;
                font-size: 22px !important;
                @media screen and (min-width: $breakpoint-medium) {
                    font-size: 25px !important;
                }
            }
        }
    }
}

.label-value-container {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    @media screen and (min-width: $breakpoint-small) {
        flex-direction: row;
    }

    label {
        font-family: $font-semi-bold;
        font-size: 16px;
        color: #313131;
        min-width: 153px; // 137 + 16
    }

    .label-desktop {
        @media screen and (min-width: $breakpoint-small) {
            display: block;
        }
    }
    .value {
        color: #313131;
        font-size: 16px;
        font-family: $font-regular;
        margin-top: 7px;
        @media screen and (min-width: $breakpoint-small) {
            margin-top: 0px;
        }
    }
}

.gallery-desktop {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;

    .gallery-container {
        margin-top: 16px;
        display: flex;
        align-self: center;

        .image-gallery {
            .image-gallery-content {
                padding: 0;
                border: #d0d0d0 solid 1px;
                padding: 5px 5px;
                @media screen and (min-width: 600px) {
                    padding: 10px 0px;
                    padding-left: 10px;
                    padding-right: 2px;
                }

                &.fullscreen {
                    border: none;
                    padding: 0px 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                    .image-gallery-slides {
                        height: 100vh;
                        img {
                            max-width: inherit;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .image-gallery-slide-wrapper {
                        &.right {
                            width: 100%;
                        }
                    }
                }

                .image-gallery-slide-wrapper {
                    &.right {
                        @media screen and (max-width: 600px) {
                            width: calc(100vw - 50px);
                        }
                    }
                }
            }
            .image-gallery-thumbnail {
                height: 120px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }

                &.active {
                    border: 4px solid #337ab7;
                }
            }

            .image-gallery-slides {
                height: 400px;
                @media screen and (min-width: $breakpoint-small) {
                    height: 600px;
                }
                .image-gallery-slide {
                    max-height: 100vh;
                    height: inherit;
                    img {
                        @media screen and (min-width: 600px) {
                            width: 450px;
                        }
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .label-mobile-container {
        cursor: pointer;
        display: flex;
        padding: 17px 20px;
        background: #c6e7f1;
        width: 100%;
        justify-content: space-between;
        @media screen and (min-width: $breakpoint-small) {
            display: none;
        }

        label {
            font-family: $font-semi-bold;
            font-size: 16px;
            color: #313131;
            min-width: 153px; // 137 + 16
        }

        .arrow-icon {
            background-image: url("../../../assets/images/arrow_right.svg");
            background-repeat: no-repeat;
            background-position: 0px -2px;
            padding-left: 0px;
            padding-right: 10px;
            width: 24px;
            height: 24px;
        }
    }
}

.info-pay-fine {
    padding-left: 24px;
    padding-right: 24px;
    @media screen and (min-width: $breakpoint-medium) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media screen and (min-width: $breakpoint-large) {
        max-width: 432px;
        font-size: 14px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .laws-regulations {
        .legislation-item {
            padding-bottom: 25px;
            border-bottom: solid 1px #d0d0d0;
        }
        .order-pay-fine-item {
            margin-bottom: 30px;
            padding-top: 25px;
        }
        @media screen and (min-width: $breakpoint-medium) {
            display: flex;
            .legislation-item {
                padding-bottom: 0px;
                border-bottom: none;
                padding-right: 15px;
            }
            .order-pay-fine-item {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 15px;
                border-left: solid 1px #313131;
            }
        }
        @media screen and (min-width: $breakpoint-large) {
            display: block;
            .legislation-item {
                padding-bottom: 10px;
                border-bottom: none;
                padding-right: 0px;
            }
            .order-pay-fine-item {
                padding-top: 10px;
                padding-left: 0px;
                border-left: none;
            }
        }
    }

    .phone {
        margin-top: 11px;
        font-size: 16px;
        font-family: $font-semi-bold;
    }
}

.violation-line {
    border-top: #d0d0d0 solid 1px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
}

.status-fine-container {
    box-shadow: 0px 0px 18px #00000029;
    padding: 5px 20px 20px 20px;
    display: none;
    @media screen and (min-width: $breakpoint-large) {
        display: block;
    }
    h2 {
        margin-bottom: 7px;
    }
}

.payment-gateway {
    font-size: 14px;
    @media screen and (min-width: $breakpoint-medium) {
        font-size: 12px;
    }
    @media screen and (min-width: $breakpoint-large) {
        font-size: 14px;
    }
    background: url("../../../assets/images/easy_pay.png") no-repeat;
    background-position: right;
    background-size: 65px;
    margin: 0 auto;
    width: 180px;
    padding-top: 6px;
    display: flex;
    align-items: center;
    height: 40px;
}

.violation-status {
    font-size: 16px;
    @media screen and (min-width: $breakpoint-medium) {
        //font-size: 16px;
    }
    @media screen and (min-width: $breakpoint-large) {
        font-size: 19px;
    }
}

.receipt-container {
    .receipt-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: column;
        }
        .receipt-label {
            text-transform: uppercase;
            font-size: 14px;
            margin-top: 15px;
        }
        .receipt-value {
            font-family: $font-semi-bold;
            margin-top: 2px;
            font-size: 14px;
        }
    }
}

.pay-button {
    height: 53px;
    width: 100%;
    margin-top: 15px;
    font-size: 18px;
    @media screen and (min-width: $breakpoint-medium) {
        margin-top: 15px;
        font-size: 14px;
        height: 42px;
    }
    @media screen and (min-width: $breakpoint-large) {
        font-size: 18px;
    }
    margin-bottom: 5px;
    cursor: pointer;
}

.violation-status-indicator {
    height: 68px;
    font-family: $font-semi-bold;
    border-radius: 6px;
    min-width: 218px;
    display: flex;
    justify-content: center;
    align-items: center;
    //max-width: 200px;
    &.green {
        background-color: #d2f1b3;
    }
    &.red {
        background-color: #ff9595;
    }
    @media screen and (min-width: $breakpoint-medium) {
        height: 48px;
    }
    @media screen and (min-width: $breakpoint-large) {
        margin-top: 20px;
    }
}

.violation-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e4eef182;
    padding: 0px 25px 25px 25px;
    margin-top: 16px;
    margin-bottom: 10px;
    @media screen and (min-width: $breakpoint-small) {
        padding: 0px 30px 30px 30px;
    }
    .description {
        width: 100%;

        &.with-ellipsis {
            height: 45px;
            display: flex;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.without-ellipsis {
            span {
                float: left;
                padding-bottom: 3px;
            }
        }
        span {
            font-family: $font-regular;
            font-size: 14px;
            color: #313131;
            line-height: 1.7;
        }
    }
    .read-more {
        cursor: pointer;
        color: #039ceb;
        margin-top: 5px;
    }
}

.error-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .error-message {
        align-self: center;

        text-align: center;
        margin-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 240px;
        @media screen and (min-width: $breakpoint-large) {
            margin-bottom: 20px;
        }
        .error-text {
            margin-top: -24px;
            font-size: 25px;
            font-family: $font-semi-bold;
            @media screen and (min-width: $breakpoint-large) {
                margin-top: 0px;
            }
        }
    }
}

.pay-bonus {
    line-height: 1.7;
}

p {
    line-height: 1.7;
}
