* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    color: #313131;
}

html, body, #root, .app {
    height: 100%;
};

body, button, input, select, optgroup, textarea {
    @include font-size(1);

    font-family: $font-regular;
}

h1, h2, h3, h4, h5, h6 {
font-weight: 400;
}

h3 {
    @include font-size(1);
    font-family: $font-medium;
    margin: 15px 8px 10px;
}

a {
    text-decoration: none;
    color: #039CEB;
}

.link{
    color: #039CEB;
}

.general-wrapper{
    position: relative;
    max-width: 1920px;
    width: 100%;
    //overflow: auto;
    margin: 0 auto;
}

.container{
    margin-left: $general-margin-small;
    margin-right: $general-margin-small;
    @media screen and (min-width: $breakpoint-medium) {
        margin-left: $general-margin-medium;
        margin-right: $general-margin-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
        margin-left: $general-margin;
        margin-right: $general-margin;
    }
}

.left-container{
    width: 100%;
    margin-right: $general-margin-small;
    @media screen and (min-width: $breakpoint-medium) {
        margin-right: $general-margin-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
        margin-right: $general-margin;
    }
}

.right-container{
    //width: 100%;
    margin-left: $general-margin-small;
    @media screen and (min-width: $breakpoint-medium) {
        margin-left: $general-margin-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
        margin-left: 125px;
    }
    @media screen and (min-width: $breakpoint-extra-large) {
        margin-left: $general-margin;
    }
}

.wide-container{
    max-width: 1000px;
    margin: 0 auto;
    padding-left: $general-margin-small;
    padding-right: $general-margin-small;
    @media screen and (min-width: $breakpoint-large) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

input[type=text] {
    border: solid 1px #B7B7B7;
    border-radius: 5px;
    //height: 53px;
    background-color: #ffffff;
}

button {
    font-family: $font-bold;
}

img{
    width: 100%;
    height: auto;
}

.dialog-container{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    .dialog-content{
        flex: 0 0 auto;
        overflow: auto;
        //height: 100%;
        width: 94%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        box-shadow: 0px 0px 22px #00000029;
        // max-height:  500px;
        // height: 100%;
        // @media screen and (min-width: 360px) {
        //     max-height:  710px;
        // }
        .close{
            position: absolute;
            cursor: pointer;
            float: right;
            right: 10px;
            top: 10px;
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: .5;
            border: none;
            outline: none;
            background-color: transparent;
            background: url('../assets/images/close.svg') no-repeat;
            width: 24px;
            height: 24px;
        }
        &.violation-modal{
            max-width: 714px;
            //max-height:  557px;
            // @media screen and (min-width: $breakpoint-medium){
            //     max-width: 714px;
            //     max-height:  310px;
            // }
        }
        .modal-header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 50px 20px 0 20px;
            //padding-bottom: 0px;
            .modal-title{
                font-family: $font-bold;
                font-size: 22px;
            }
            .close {
                cursor: pointer;
                float: right;
                font-size: 24px;
                font-weight: 700;
                line-height: 1;
                color: #000;
                text-shadow: 0 1px 0 #fff;
                opacity: .5;
                border: none;
                outline: none;
                background-color: transparent;
                background: url('../assets/images/close.svg') no-repeat;
                min-width: 24px;
                min-height: 24px;
            }
        }
        .modal-body{
            //padding: 20px;
            padding: 10px 20px;

            .order-pay-fine-modal{
                .order-pay-description{
                    p{
                        font-size: 14px;
                        line-height: 1.5;

                        &:first-child{
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
        .modal-footer{
            border-top: solid 1px #A5B5B9;
           // padding: 20px;
           padding: 20px  20px 20px;
            display: flex;
            justify-content: center;
            button{
                min-width: 100px;
                height: 40px;
                cursor: pointer;
                font-size: 14px;
            }
        }
        
        .button-pay-online{
            margin-left: 1.8rem;
        }
    }
}

.button-up{
    background-image: url('../assets/images/button_up.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 34px;
    height: 34px;
}

.button-up-container {
    position: fixed;
    right: -100px;
    bottom: 150px;
    transition: right 0.5s;
    cursor: pointer;
}

.button-up-for-transition {
    right: 20px;
}

.bold{
    font-family: $font-bold;
}

